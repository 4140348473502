import * as React from 'react'

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { Divider, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LockPersonIcon from '@mui/icons-material/LockPerson'

import Button from './Button'

export type MenuOption = {
  label: string
  onClick: () => void
  divideAfter?: boolean
  icon?: React.ReactNode
  disabled?: boolean
  locked?: boolean
  lockedTooltip?: string
}

interface DropdownButtonProps extends ButtonProps {
  options: MenuOption[]
  disabledTooltip?: string
  locked?: boolean
  lockedTooltip?: string
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  options,
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  disabledTooltip,
  locked,
  lockedTooltip,
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!options.length) {
      console.error('No options provided for DropdownButton')
      return
    }

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        endIcon={<ArrowDropDownRoundedIcon />}
        {...buttonProps}
        onClick={handleClick}
        disabledTooltip={disabledTooltip}
        locked={locked}
        lockedTooltip={lockedTooltip}
      >
        {buttonProps.children}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="mt-1"
      >
        {options.reduce((acc, option, index) => {
          acc.push(
            <Tooltip
              key={`tooltip-${index}`}
              title={(option.locked && option.lockedTooltip) || ''}
              arrow
            >
              <span>
                <MenuItem
                  key={index}
                  disabled={option.disabled || option.locked}
                  onClick={() => {
                    option.onClick()
                    handleClose()
                  }}
                >
                  {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                  <ListItemText>{option.label}</ListItemText>
                  {option.locked && (
                    <LockPersonIcon fontSize="small" sx={{ ml: 1 }} />
                  )}
                </MenuItem>
              </span>
            </Tooltip>
          )
          if (option.divideAfter) {
            acc.push(<Divider key={`divider-${index}`} />)
          }
          return acc
        }, [])}
      </Menu>
    </div>
  )
}

export default DropdownButton
