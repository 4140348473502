import * as React from 'react'

import { Chip, Tooltip } from '@mui/material'
import type { BadgeTypeMap, TooltipProps } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
interface TabProps {
  tabClassName?: string
  label: string
  disabled?: boolean
  index?: number | string
  icon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  iconPosition?: 'bottom' | 'top' | 'end' | 'start'
  inboxCount?: number
  inboxCountVariant?: 'filled' | 'outlined'
  inboxCountColor?: BadgeTypeMap['props']['color']
  activeColor?: string
  tooltip?: string
  tooltipPlacement?: TooltipProps['placement']
  children?: React.ReactElement | React.ReactElement[]
  value?: number | string
}

interface TabPanelProps {
  children?: React.ReactNode
  value: number | string
  index: number
}

export interface ImperativeHandleRefProps {
  selectedTab: number
}

interface ReusableTabsProps {
  variant?: 'standard' | 'scrollable' | 'fullWidth'
  className?: string
  tabsClassName?: string
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>
  onTabChange?: (tabIndex: number | string) => void
  value?: number | string
  ariaLabel?: string
  dotColor?: BadgeTypeMap['props']['color']
  overrideActiveTabColor?: string
}

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  )
}

export const TabComponent: React.FC<TabProps> = () => <></>

const ReusableTabs: React.FC<ReusableTabsProps> = ({
  children,
  className,
  tabsClassName,
  variant = 'standard',
  onTabChange,
  value,
  ariaLabel = 'basic tabs example',
  overrideActiveTabColor,
}) => {
  const [selectedTab, setSelectedTab] = React.useState<number | string>(
    value ?? 0
  )
  const [activeTabColor, setActiveTabColor] = React.useState<string>('')

  React.useEffect(() => {
    setActiveTabColor('')
  }, [selectedTab])

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number | string
  ) => {
    setSelectedTab(newValue)
    //TODO ww-433: The tab value used here is tabIndex which is not user friendly while re-using
    // useTabbedNavigation since now the url will have hash value that says something like
    // url#0, url#1 instead of url#upcoming, url#history
    onTabChange?.(newValue)
  }

  React.useEffect(() => {
    if (value !== undefined) setSelectedTab(value)
  }, [value])

  // Filter out invalid children, if the children are not valid,
  // the Tabs component will throw an error
  const validChildren = [] as typeof children
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      validChildren.push(child)
    }
  })

  const getChipColorProps = (index: number, inboxCountColor) => {
    if (selectedTab === index) {
      if (overrideActiveTabColor) {
        return {
          style: {
            backgroundColor: overrideActiveTabColor,
            color: 'white',
          },
        }
      }
      if (activeTabColor) {
        return {
          style: {
            backgroundColor: activeTabColor,
            color: 'white',
          },
        }
      }
    }
    return {
      color: inboxCountColor,
    }
  }

  const getTabStyles = (index: number) => {
    if (selectedTab === index) {
      if (overrideActiveTabColor) {
        return {
          color: overrideActiveTabColor,
        }
      }
      if (activeTabColor) {
        return {
          color: activeTabColor,
        }
      }
    }
    return {}
  }

  const getTabIndicatorStyles = () => {
    if (overrideActiveTabColor) {
      return {
        backgroundColor: overrideActiveTabColor,
      }
    }
    if (activeTabColor) {
      return {
        backgroundColor: activeTabColor,
      }
    }
    return {}
  }

  return (
    <div className={`w-full ${className}`}>
      <div
        className={`border-[0] border-b border-solid border-gray-300 ${tabsClassName}`}
      >
        <Tabs
          value={selectedTab}
          variant={variant}
          onChange={handleChange}
          aria-label={ariaLabel}
          TabIndicatorProps={{ style: getTabIndicatorStyles() }}
        >
          {React.Children.map(validChildren, (child, index) => {
            const {
              props: {
                tabClassName,
                label,
                disabled,
                icon,
                iconPosition,
                inboxCount,
                inboxCountVariant = 'filled',
                inboxCountColor = 'primary',
                activeColor = '',
                tooltip = '',
                tooltipPlacement = 'top',
              },
            } = child
            if (
              activeColor &&
              selectedTab === index &&
              !overrideActiveTabColor &&
              !activeTabColor
            ) {
              setActiveTabColor(activeColor)
            }

            return (
              <Tooltip
                title={tooltip}
                placement={tooltipPlacement}
                value={child.props.value ?? index}
              >
                <Tab
                  className={tabClassName}
                  style={getTabStyles(index)}
                  label={
                    <div className="flex items-center gap-1">
                      {label}
                      {inboxCount > 0 && (
                        <Chip
                          className="cursor-pointer"
                          label={inboxCount}
                          size="small"
                          variant={inboxCountVariant}
                          {...getChipColorProps(index, inboxCountColor)}
                        />
                      )}
                    </div>
                  }
                  disabled={disabled}
                  {...a11yProps(index)}
                  icon={icon}
                  iconPosition={iconPosition}
                  value={child.props.value ?? index}
                />
              </Tooltip>
            )
          })}
        </Tabs>
      </div>
      {React.Children.map(validChildren, (child, arrIndex) => {
        const index = child.props.value ?? arrIndex
        const isVisible = selectedTab === index
        return (
          <TabPanel value={selectedTab} index={index}>
            {child.props.children
              ? React.cloneElement(child.props.children, { visible: isVisible })
              : null}
          </TabPanel>
        )
      })}
    </div>
  )
}

export default ReusableTabs
