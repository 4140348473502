import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Table as MUITable,
  TableSortLabel,
} from '@mui/material'

import DotsMenu from '../MUI/DotsMenu/DotsMenu'

interface HeadCell {
  id: string | number
  align?: 'left' | 'center' | 'right' | 'justify' | 'char'
  className?: string
  label?: string
  component?: React.ReactNode
}

interface TableHeaderProps {
  order: string // I don't know the exact type, so you might need to adjust this
  orderBy: string | number
  onRequestSort: (event: any, property: string | number) => void // Replace 'any' with a more specific type if you know what event object is being passed
  config: HeadCell[]
  sortableColumns?: string[]
}

export type FilterBarProps = {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  localSearchVal: string
}

const TableHeader: React.FC<TableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
  config,
  sortableColumns,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {config.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.align || 'left'}
            className={headCell.className}
          >
            {headCell.component ? (
              headCell.component
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className="text-[#9E9E9E]"
                disabled={
                  sortableColumns && !sortableColumns.includes(headCell.id)
                }
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export type DataProp = {
  id: string
  [key: string]: unknown
}

interface TableProps {
  title: string
  classes?: { tableContainer?: string }
  data: DataProp[]
  order?: 'asc' | 'desc'
  orderBy?: string
  slots: {
    Row: React.FC<{ data: DataProp }>
    Actions?: React.FC
    FilterBar?: React.FC<FilterBarProps>
  }
  RowProps?: object
  FilterBarProps?: FilterBarProps
  dotOptions?: { label: string; onClick: () => void }[]
  columnConfig: HeadCell[]
  container?: 'paper' | 'fragment'
  handleRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void
  sortableColumns?: string[]
  tableContainerClassName?: string
}

const Table: React.FC<TableProps> = ({
  title,
  data = [],
  classes = {},
  slots: { Row, Actions, FilterBar },
  order,
  dotOptions = [],
  RowProps = {},
  FilterBarProps,
  handleRequestSort,
  orderBy,
  columnConfig,
  container = 'paper',
  sortableColumns,
  tableContainerClassName = '',
}): JSX.Element => {
  const Container = container === 'paper' ? Paper : React.Fragment
  return (
    <Container className={container === 'paper' ? 'rounded-2xl pb-2 pt-2' : ''}>
      <div className="mx-2 mb-1 flex items-center justify-between">
        <Typography variant="h6" component="h2" className="font-medium">
          {title}
        </Typography>
        <div className="flex items-center gap-1">
          {Actions && <Actions />}
          {dotOptions.length > 0 && (
            <DotsMenu
              options={dotOptions}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            />
          )}
        </div>
      </div>
      {FilterBar && (
        <div className="mx-2">
          <FilterBar {...FilterBarProps} />
        </div>
      )}
      <TableContainer classes={classes} className={tableContainerClassName}>
        <MUITable stickyHeader>
          <TableHeader
            config={columnConfig}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            sortableColumns={sortableColumns}
          />
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => <Row key={row.id} {...RowProps} data={row} />)
            ) : (
              <TableRow>
                <TableCell colSpan={columnConfig.length} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No items to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
    </Container>
  )
}

export default Table
