import { isEmpty } from 'lodash'

import {
  DueListTableFilterData,
  DuelistFilterDataBy,
  DuelistFilterProjection,
  DuelistFilterTimeframeCycles,
  DuelistFilterTimeframeDays,
  DuelistFilterTimeframeHours,
  DuelistFilterTimeframeLandings,
  SelectedInProgress,
  UnknownBooleanObject,
} from 'src/types'
import { isNotEmpty } from 'src/utils/helpers'

import { RootState } from '../reducers'
import {
  initialProjectionFilterData,
  initialFilterData,
  setDuelistFiltersData,
  setDuelistColumnsData,
  setShowChildItems as setShowChildItemsReducer,
  dueListItemSelected,
  dueListItemUnselected,
  unselectAllMaintenanceItems as unselectAllDueListItems,
} from '../slices/dueListTableSlice'

import { useDispatch } from './useDispatch'
import { useSelector } from './useSelector'
import {
  setMaintenanceItemColumnsData,
  setMaintenanceItemFiltersData,
  setShowChildMaintenanceItems,
  unselectAllMaintenanceItems,
  maintenanceItemSelected,
  maintenanceItemUnselected,
} from 'src/slices/maintenanceItemTableSlice'
import { MaintenanceItem } from 'types/graphql'

export type TableVariant = 'duelist' | 'maintenanceItems'

type TimeframeFilters = {
  timeFrameAll: boolean
  timeframeDays: DuelistFilterTimeframeDays
  timeframeHours: DuelistFilterTimeframeHours
  timeframeCycles: DuelistFilterTimeframeCycles
  timeframeLandings: DuelistFilterTimeframeLandings
}

interface DueListTableFiltersHook {
  duelistFiltersData: DueListTableFilterData
  setDuelistFilters: (data: DueListTableFilterData) => void
  setFuzzySearchTerms: (searchTerms: string) => void
  setPowerSearchTerms: (searchTerms: any) => void
  setDueStatus: (key: string, value: boolean) => void
  setInterval: (key: string, value: boolean) => void
  setTimeframeAll: (timeframe: boolean) => void
  setTimeframeDays: (timeframe: DuelistFilterTimeframeDays) => void
  setTimeframeHours: (timeframe: DuelistFilterTimeframeHours) => void
  setTimeframeCycles: (timeframe: DuelistFilterTimeframeCycles) => void
  setTimeframeLandings: (timeframe: DuelistFilterTimeframeLandings) => void
  setAircraftFilter: (key: string, value: boolean) => void
  setProjectionFilters: (value: DuelistFilterProjection) => void
  setDefaultDynamicFilters: (aircraftFilters: UnknownBooleanObject) => void
  setAssignedMechanicFilter: (key: string, value: boolean) => void
  setFilterDataBy: (value: DuelistFilterDataBy[]) => void
  unselectAll: () => void
  setTableRefreshKey: () => void
  resetetAircraftFilter: () => void
  areProjectionsApplied: () => boolean
  removeProjectionFilters: () => void
  resetFilters: () => void
  getSearchConfig: () => { value: any; variables: any }
  //local
  setEnabledColumns: (columns: string[]) => void
  enabledColumns: string[]
  setShowChildItems: (showChildItems: boolean) => void
  showChildItems: boolean
  aircraftFilter: DueListTableFilterData['aircrafts']

  // Selection
  selectMaintenanceItem: (item: MaintenanceItem | MaintenanceItem[]) => void
  deselectMaintenanceItem: (item: MaintenanceItem | MaintenanceItem[]) => void
  selectedItemIds: string[]
  selectedInProgressItems: SelectedInProgress
}

const useMaintenanceItemTable = (
  storeType: TableVariant
): DueListTableFiltersHook => {
  const dispatch = useDispatch()
  const maintenanceItemTableState = useSelector((state: RootState) =>
    storeType === 'maintenanceItems'
      ? state.maintenanceItemTable
      : state.dueListTable
  )
  const filterData = maintenanceItemTableState.filters

  const setEnabledColumns = (columns: string[]): void => {
    if (storeType === 'maintenanceItems') {
      dispatch(setMaintenanceItemColumnsData(columns))
    } else {
      dispatch(setDuelistColumnsData(columns))
    }
  }

  const setShowChildItems = (showChildItems: boolean): void => {
    if (storeType === 'maintenanceItems') {
      dispatch(setShowChildMaintenanceItems(showChildItems))
    } else {
      dispatch(setShowChildItemsReducer(showChildItems))
    }
  }

  const setDuelistFilters = (data: DueListTableFilterData): void => {
    if (storeType === 'maintenanceItems') {
      dispatch(setMaintenanceItemFiltersData(data))
    } else {
      dispatch(setDuelistFiltersData(data))
    }
  }

  const unselectAll = () => {
    if (storeType === 'maintenanceItems') {
      dispatch(unselectAllMaintenanceItems())
    } else {
      dispatch(unselectAllDueListItems())
    }
  }

  const selectMaintenanceItem = (item: MaintenanceItem) => {
    if (storeType === 'maintenanceItems') {
      dispatch(maintenanceItemSelected(item))
    } else {
      dispatch(dueListItemSelected(item))
    }
  }

  const deselectMaintenanceItem = (item: MaintenanceItem) => {
    if (storeType === 'maintenanceItems') {
      dispatch(maintenanceItemUnselected(item))
    } else {
      dispatch(dueListItemUnselected(item))
    }
  }

  const setFuzzySearchTerms = (searchTerms: string): void => {
    setDuelistFilters({
      ...filterData,
      fuzzySearchTerms: searchTerms,
      powerSearchTerms: {},
    })
  }

  const setPowerSearchTerms = (searchTerms: any): void => {
    setDuelistFilters({
      ...filterData,
      powerSearchTerms: searchTerms,
      fuzzySearchTerms: '',
    })
  }
  const setDueStatus = (key: string, value: boolean): void => {
    setDuelistFilters({
      ...filterData,
      dueStatus: {
        ...filterData.dueStatus,
        [key]: value,
      },
    })
  }

  const setInterval = (key: string, value: boolean): void => {
    setDuelistFilters({
      ...filterData,
      interval: {
        ...filterData.interval,
        [key]: value,
      },
    })
  }

  const resetTimeframes = (): TimeframeFilters => ({
    timeFrameAll: false,
    timeframeDays: {
      option: '',
      days: -1,
    },
    timeframeHours: {
      hours: -1,
      option: '',
    },
    timeframeCycles: {
      cycles: -1,
      option: '',
    },
    timeframeLandings: {
      landings: -1,
      option: '',
    },
  })

  const setDuelistFilterTimeframe = (data: Partial<TimeframeFilters>): void => {
    setDuelistFilters({
      ...filterData,
      ...resetTimeframes(),
      //resetting projections when individual timeframe is selected
      projectionFilterData: initialProjectionFilterData,
      ...data,
    })
  }

  const setTimeframeAll = (all: boolean): void => {
    setDuelistFilterTimeframe({ timeFrameAll: all })
  }

  const setTimeframeDays = (data: DuelistFilterTimeframeDays): void => {
    setDuelistFilterTimeframe({ timeframeDays: data })
  }

  const setTimeframeHours = (data: DuelistFilterTimeframeHours): void => {
    setDuelistFilterTimeframe({ timeframeHours: data })
  }

  const setTimeframeCycles = (data: DuelistFilterTimeframeCycles): void => {
    setDuelistFilterTimeframe({ timeframeCycles: data })
  }

  const setTimeframeLandings = (data: DuelistFilterTimeframeLandings): void => {
    setDuelistFilterTimeframe({ timeframeLandings: data })
  }

  const setAircraftFilter = (key: string, value: boolean): void => {
    setDuelistFilters({
      ...filterData,
      aircrafts: {
        ...filterData.aircrafts,
        [key]: value,
      },
    })
  }

  const resetetAircraftFilter = (): void => {
    setDuelistFilters({
      ...filterData,
      aircrafts: {},
    })
  }

  const setDefaultDynamicFilters = (
    aircraftFilters: UnknownBooleanObject
  ): void => {
    setDuelistFilters({
      ...filterData,
      aircrafts: aircraftFilters,
    })
  }

  const setAssignedMechanicFilter = (key: string, value: boolean): void => {
    setDuelistFilters({
      ...filterData,
      assignedMechanic: {
        ...filterData.assignedMechanic,
        [key]: value,
      },
    })
  }

  const setFilterDataBy = (value: DuelistFilterDataBy[]): void => {
    setDuelistFilters({
      ...filterData,
      filterDataBy: value,
      tableRefreshKey: filterData.tableRefreshKey + 1,
    })
  }

  const setTableRefreshKey = (): void => {
    setDuelistFilters({
      ...filterData,
      tableRefreshKey: filterData.tableRefreshKey + 1,
    })
  }

  const setProjectionFilters = (value: DuelistFilterProjection): void => {
    setDuelistFilters({
      ...filterData,
      ...resetTimeframes(),
      //resetting individual timeframe when projections are selected
      timeFrameAll: true,
      projectionFilterData: value,
    })
  }

  const removeProjectionFilters = (): void => {
    const data = { ...initialProjectionFilterData }
    data.date = undefined
    data.projectionGrid = data.projectionGrid.map((projection) => {
      return { ...projection, days: 1 }
    })
    setProjectionFilters(data)
  }

  const areProjectionsApplied = (): boolean => {
    const { projectionFilterData } = filterData
    return (
      //ignore the undefined values in initialProjectionFilterData
      projectionFilterData.date !== undefined &&
      projectionFilterData.date !== ''
    )
  }

  const resetFilters = (): void => {
    setDuelistFilters(initialFilterData)
  }

  const getSearchConfig = () => {
    const { powerSearchTerms, fuzzySearchTerms } = filterData
    if (isEmpty(powerSearchTerms) && isNotEmpty(fuzzySearchTerms)) {
      return {
        value: fuzzySearchTerms,
        variables: { fuzzySearch: fuzzySearchTerms },
      }
    }
    if (isEmpty(fuzzySearchTerms) && isNotEmpty(powerSearchTerms)) {
      return {
        value: powerSearchTerms,
        variables: { powerSearch: powerSearchTerms },
      }
    }
    return { value: {}, variables: {} }
  }

  return {
    duelistFiltersData: filterData,
    setFuzzySearchTerms,
    setPowerSearchTerms,
    setDueStatus,
    setInterval,
    setTimeframeAll,
    setTimeframeCycles,
    setTimeframeDays,
    setTimeframeHours,
    setAircraftFilter,
    setAssignedMechanicFilter,
    setDefaultDynamicFilters,
    resetetAircraftFilter,
    setFilterDataBy,
    setTableRefreshKey,
    setProjectionFilters,
    setDuelistFilters,
    setTimeframeLandings,
    removeProjectionFilters,
    areProjectionsApplied,
    resetFilters,
    getSearchConfig,
    setEnabledColumns,
    enabledColumns: maintenanceItemTableState.columnsToShow,
    setShowChildItems,
    showChildItems: maintenanceItemTableState.showChildItems,
    aircraftFilter: filterData.aircrafts,

    // Selection
    selectMaintenanceItem,
    deselectMaintenanceItem,
    unselectAll,
    selectedItemIds: maintenanceItemTableState.selectedItems,
    selectedInProgressItems: maintenanceItemTableState.selectedInProgressItems,
  }
}

export default useMaintenanceItemTable
