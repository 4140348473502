import React, { useEffect } from 'react'

import {
  ClickAwayListener,
  Popper,
  ToggleButton,
  Button,
  styled,
} from '@mui/material'

import DropdownOption from '../common/DropdownOption'

type DropdownButtonMenuProps<
  T extends { option: string } & Record<string, any>
> = {
  fieldName: string
  buttonPosition: 'left' | 'right' | 'center' // Determines whether or not to apply a border radius to the button so that the middle buttons look like they are part of the same group
  title: string
  buttonActive: boolean
  defaultData: T
  onApply: (data: T) => void
  options: (T & { label: string })[]
}

const DropdownWrapper = styled('div')(() => ({
  marginTop: '8px',
  background: '#FFFFFF',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
}))

const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== 'buttonPosition',
})<{ buttonPosition: 'left' | 'right' | 'center' }>(({ buttonPosition }) => ({
  borderTopRightRadius: buttonPosition === 'right' ? undefined : '0px',
  borderBottomRightRadius: buttonPosition === 'right' ? undefined : '0px',
  borderTopLeftRadius: buttonPosition === 'left' ? undefined : '0px',
  borderBottomLeftRadius: buttonPosition === 'left' ? undefined : '0px',
}))

const DropdownButtonMenu = <
  T extends { option: string } & Record<string, any>
>({
  onApply,
  buttonActive,
  buttonPosition,
  fieldName,
  title,
  defaultData,
  options,
}: DropdownButtonMenuProps<T>) => {
  const [selected, setSelected] = React.useState<T>()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    setSelected(defaultData)
  }, [defaultData])

  const onDropdownButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const onApplyClick = () => {
    setAnchorEl(null)
    onApply(selected)
  }

  const onCancel = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <StyledToggleButton
        aria-describedby={id}
        onClick={onDropdownButtonClick}
        value={fieldName}
        size="small"
        selected={buttonActive}
        buttonPosition={buttonPosition}
      >
        {title}
      </StyledToggleButton>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onCancel}>
        <Popper
          id={id}
          open={open}
          placement="bottom-start"
          anchorEl={anchorEl}
          style={{ zIndex: 10000 }}
        >
          <DropdownWrapper>
            {options.map((option, idx) => (
              <DropdownOption
                key={idx}
                className={selected?.option === option.option ? 'selected' : ''}
                onClick={() => setSelected(option)}
              >
                {option.label}
              </DropdownOption>
            ))}
            <div className=" flex justify-between gap-2 p-2">
              <Button size="small" onClick={onApplyClick}>
                Apply
              </Button>
              <Button
                variant="outlined"
                color="base"
                size="small"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </DropdownWrapper>
        </Popper>
      </ClickAwayListener>
    </>
  )
}

export default DropdownButtonMenu
