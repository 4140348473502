import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/reducers'

import { AppLayoutState } from 'src/types'

const drawerExpanded = localStorage.getItem('drawerExpanded')

const initialState: AppLayoutState = {
  navDrawerExpanded: drawerExpanded === null ? true : drawerExpanded === 'true',
  commentsDrawerExpanded: false,
  aiAssistantDrawerOpen: false,
}

const appLyoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setNavDrawerExpanded: (
      state: AppLayoutState,
      action: PayloadAction<boolean>
    ) => {
      state.navDrawerExpanded = action.payload
      localStorage.setItem('drawerExpanded', `${action.payload}`)
    },

    navDrawerExpandedToggled: (state: AppLayoutState) => {
      state.navDrawerExpanded = !state.navDrawerExpanded
      // TODO: should not side effects inside of a redux reducer
      localStorage.setItem('drawerExpanded', `${state.navDrawerExpanded}`)
    },

    commentsDrawerOpened: (state: AppLayoutState) => {
      state.commentsDrawerExpanded = true
    },

    commentsDrawerClosed: (state: AppLayoutState) => {
      state.commentsDrawerExpanded = false
    },

    commentsDrawerExpandedToggled: (state: AppLayoutState) => {
      state.commentsDrawerExpanded = !state.commentsDrawerExpanded
    },

    setAiAssistantDrawerOpen: (
      state: AppLayoutState,
      action: PayloadAction<boolean>
    ) => {
      state.aiAssistantDrawerOpen = action.payload
    },
  },
})

export const selectNavDrawerExpanded = (state: RootState) =>
  state.appLayout.navDrawerExpanded
export const selectCommentsDrawerExpanded = (state: RootState) =>
  state.appLayout.commentsDrawerExpanded

export const { setNavDrawerExpanded, navDrawerExpandedToggled, commentsDrawerExpandedToggled, commentsDrawerOpened, commentsDrawerClosed, setAiAssistantDrawerOpen } = appLyoutSlice.actions

export default appLyoutSlice.reducer
