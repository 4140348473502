import React, { useState, useRef, useEffect } from 'react'

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Chip, IconButton, Paper, Typography } from '@mui/material'
import { useOnClickOutside } from 'usehooks-ts'

import { HighlightedTypography } from './MUI/StyledComponents'

interface TypeAndDescriptionDisplayProps {
  description: string
  chips?: string[]
  tailTags?: string[]
  maxRows?: number
  highlight?: boolean
}

const TypeAndDescriptionDisplay: React.FC<TypeAndDescriptionDisplayProps> = ({
  description,
  chips,
  tailTags,
  maxRows = 2,
  highlight = false,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [truncatedText, setTruncatedText] = useState(description)
  const [isTruncated, setIsTruncated] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLSpanElement>(null)

  useOnClickOutside(containerRef, () => setExpanded(false))

  const onExpandToggled = (
    event: React.MouseEvent<HTMLButtonElement>,
    newValue: boolean
  ) => {
    event.stopPropagation()
    setExpanded(newValue)
  }

  const truncateText = () => {
    if (textRef.current && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight
      )
      const maxHeight = lineHeight * maxRows

      textRef.current.textContent = description

      if (
        textRef.current.offsetHeight <= maxHeight &&
        textRef.current.offsetWidth <= containerWidth
      ) {
        setTruncatedText(description)
        setIsTruncated(false)
        return
      }

      let low = 0
      let high = description.length
      let lastGood = 0

      while (low <= high) {
        const mid = Math.floor((low + high) / 2)
        textRef.current.textContent = description.slice(0, mid) + '...'

        if (
          textRef.current.offsetHeight <= maxHeight &&
          textRef.current.offsetWidth <= containerWidth
        ) {
          lastGood = mid
          low = mid + 1
        } else {
          high = mid - 1
        }
      }

      // Adjust truncation point to not break words
      while (
        lastGood > 0 &&
        description[lastGood - 1] !== ' ' &&
        description[lastGood - 1] !== '-'
      ) {
        lastGood--
      }

      // Ensure we're not leaving just a few characters on the last line
      const truncated = description.slice(0, lastGood)
      const lastLineStart = truncated.lastIndexOf('\n') + 1
      const lastLine = truncated.slice(lastLineStart)

      if (lastLine.length < 10 && lastLineStart > 0) {
        lastGood = lastLineStart - 1
      }

      setTruncatedText(description.slice(0, lastGood).trim() + '...')
      setIsTruncated(true)
    }
  }

  useEffect(() => {
    truncateText()
    const resizeObserver = new ResizeObserver(truncateText)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }
    return () => resizeObserver.disconnect()
  }, [description, maxRows])

  return (
    <div ref={containerRef}>
      {expanded ? (
        <Paper
          className="absolute top-0 z-[1] -ml-2 flex w-full items-center justify-between p-2"
          ref={containerRef}
        >
          <div className="flex flex-col gap-1">
            {highlight ? (
              <HighlightedTypography>{description}</HighlightedTypography>
            ) : (
              <Typography>{description}</Typography>
            )}
            <div className="flex w-full items-center justify-between">
              <span>
                {chips?.map((chip) => (
                  <Chip
                    key={chip}
                    label={chip}
                    className="mr-[5px] w-fit"
                    size="small"
                    variant="outlined"
                  />
                ))}
              </span>
              <span>
                {tailTags?.map((tag) => (
                  <Typography key={tag} variant="caption" component={'div'}>
                    {tag}
                  </Typography>
                ))}
              </span>
            </div>
          </div>
          <IconButton onClick={(event) => onExpandToggled(event, false)}>
            <CloseFullscreenIcon />
          </IconButton>
        </Paper>
      ) : (
        <div className="w-full items-center justify-between">
          <div className="flex flex-col gap-1">
            <div className="flex items-center">
              {highlight ? (
                <HighlightedTypography>
                  <span ref={textRef}>{truncatedText}</span>
                </HighlightedTypography>
              ) : (
                <Typography>
                  <span ref={textRef}>{truncatedText}</span>
                </Typography>
              )}
              {isTruncated && (
                <IconButton onClick={(event) => onExpandToggled(event, true)}>
                  <OpenInFullIcon />
                </IconButton>
              )}
            </div>
            {(chips || tailTags) && (
              <div className="flex w-full items-center justify-between">
                <span>
                  {chips?.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      className="mr-[5px] w-fit"
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </span>
                <span>
                  {tailTags?.map((tag) => (
                    <Typography key={tag} variant="caption" component={'div'}>
                      {tag}
                    </Typography>
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default TypeAndDescriptionDisplay
