import { MenuItem } from '@mui/material'
import clsx from 'clsx'
import { User } from 'types/graphql'

import Select from 'src/components/MUI/Select'
import TextField from 'src/components/MUI/TextField'
import { UsersDropDownSelector } from 'src/components/MUI/UsersDropDownSelector'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'

function WorkInformation({
  users = [],
  compact = false,
}: {
  users: User[]
  compact?: boolean
}) {
  const mechanics = users.filter((user) =>
    user.membership.some((member) => member.userRole === 'ADMIN')
  )

  const wrapperClasses = clsx('flex flex-col gap-2', {
    'lg:flex-row': !compact,
    'flex-row': compact,
  })

  const firstRowClasses = clsx('flex gap-2', {
    'lg:basis-1/2': !compact,
    'basis-1/2': compact,
  })

  const secondRowClasses = clsx('flex gap-2', {
    'lg:basis-1/4 lg:flex-col basis-1/2 flex-row': !compact,
    'basis-1/2 flex-row': compact,
  })

  const aircraftId = useSelector((state) => state.compliance.aircraftId)
  const canUpdateCompliance = useHasPermission(
    Permissions.compliance.update,
    aircraftId
  )

  return (
    <div className={wrapperClasses}>
      <div className={firstRowClasses}>
        <TextField
          name="task.description"
          label="Description"
          multiline
          rows={4}
          className="basis-1/2"
          disabled={!canUpdateCompliance}
        />
        <TextField
          name="task.notes"
          label="Notes"
          multiline
          rows={4}
          className="basis-1/2"
          disabled={!canUpdateCompliance}
        />
      </div>
      <div className={secondRowClasses}>
        <UsersDropDownSelector
          name="task.workedById"
          label="Mechanic"
          users={mechanics}
          fullWidth
          disabled={!canUpdateCompliance}
        />
        <TextField
          name="task.workHours"
          label="Work Hours"
          disabled={!canUpdateCompliance}
        />
      </div>
    </div>
  )
}

export default WorkInformation
