import { useEffect } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Paper, Typography } from '@mui/material'

import DueListTableFilters from '../DueListTableFilters/DueListTableFilters'

import TableWithData from './TableWithData'
import useMaintenanceItemTable from 'src/hooks/useMaintenanceItemTable'

interface DueListPickerProps {
  componentID?: string
  aircraftIds?: string[]
  onlyDueItems?: boolean
  aircraftFilterEnabled?: boolean
  onAdd: (selectedTasks: string[]) => void
  onDismiss: () => void
  onSelectItem?: (id: string | string[], isSelect?: boolean) => void
}

const DueListPicker: React.FC<DueListPickerProps> = ({
  componentID,
  onlyDueItems = true,
  aircraftFilterEnabled = true,
  aircraftIds = [],
  onAdd,
  onDismiss,
  onSelectItem,
}) => {
  const tableVariant = 'maintenanceItems' // The due list picker should only appear in screens not on the actual due list or maintenance item tables, so just use maintenanceItems as the default
  const [loading, setLoading] = React.useState(false)
  const { selectedItemIds, unselectAll } = useMaintenanceItemTable(tableVariant)

  useEffect(() => {
    // Fire once to clean due list selected state on mount.
    // e.g. user that selected items in due list page does not reset when navigating to this page.
    unselectAll()
  }, [])

  return (
    <Paper className="mt-2 overflow-x-scroll rounded-2xl p-2">
      <div className="flex items-center justify-between">
        <Typography variant="h6" component="h6" className="mb-2">
          {selectedItemIds.length} Selected Tasks
        </Typography>
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <Button
              onClick={() => onDismiss()}
              size="small"
              variant="outlined"
              color="base"
            >
              Back
            </Button>
            <LoadingButton
              onClick={() => {
                setLoading(true)
                onAdd(selectedItemIds)
              }}
              size="small"
              loading={loading}
            >
              Add
            </LoadingButton>
          </div>
        </div>
      </div>

      <DueListTableFilters
        aircraftFilterEnabled={aircraftFilterEnabled}
        tableVariant={tableVariant}
      />
      <TableWithData
        variant={tableVariant}
        onSelectItem={onSelectItem}
        queryVariables={{
          isChildItem: false,
          parentId: null,
          showInProgress: false,
          componentID,
          onlyDueItems,
          aircraftIds,
        }}
        discriminator="dueListPicker"
      />
    </Paper>
  )
}

export default DueListPicker
