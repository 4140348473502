import React, { useEffect, useMemo } from 'react'

import { Button, Checkbox as MUICheckbox, TextField } from '@mui/material'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import useAircraftList from 'web/src/hooks/requests/useAircraftRecords'
import { useOrgName } from 'web/src/hooks/useOrgName'

import { Controller, Form, useForm } from '@redwoodjs/forms'

import Checkbox from 'src/components/MUI/Checkbox'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'
import { initialProjectionFilterData } from 'src/slices/dueListTableSlice'
import { DuelistFilterProjection } from 'src/types'
import { roundToPrecision } from 'src/utils/helpers'

import LoadingOverlay from '../LoadingOverlay'
import Autocomplete, { AutocompleteOption } from '../MUI/Autocomplete'
import DatePicker from '../MUI/DatePicker'
import ReusableModal from '../MUI/Modal'
import SelectDropdown, { SelectOption } from '../MUI/SelectDropdown'

interface ProjectionsAndFilterModalProps {
  open: boolean
  tableVariant: TableVariant
  onUpdateFilter: (projectionData: DuelistFilterProjection) => void
  onClose: () => void
}

interface GridRowChangeEvent {
  target: {
    values: any
    row: any
  }
}

const getProjectionGridColumns = (
  onChange: (event: GridRowChangeEvent) => void
): GridColDef[] => {
  const columns: GridColDef[] = [
    { field: 'metric', headerName: 'METRIC', width: 150, flex: 1 },
    {
      field: 'dailyUse',
      headerName: 'DAILY USE',
      width: 120,
      editable: false,
      sortable: false,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => {
        const value = params.row.dailyUse
        return (
          <TextField
            value={value}
            onChange={(event) => {
              onChange({
                target: {
                  values: {
                    ...params.row,
                    dailyUse: roundToPrecision(parseFloat(event.target.value)),
                  },
                  row: params.row,
                },
              })
            }}
            type="number"
          />
        )
      },
    },
    {
      field: 'fakeX',
      headerName: '',
      width: 20,
      editable: false,
      sortable: false,
      valueGetter: () => 'X',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'days',
      headerName: 'DAYS',
      type: 'number',
      width: 120,
      editable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'override',
      headerName: 'OVERRIDE',
      type: 'boolean',
      width: 120,
      editable: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => {
        return (
          <MUICheckbox
            checked={params.row.override}
            onChange={() => {
              let overrideProjectionVal = 1
              // This is executed before value change hence the reverse logic
              if (params.row.override) {
                overrideProjectionVal = params.row.projection
                overrideProjectionVal = Math.ceil(
                  params.row.dailyUse * params.row.days
                )
              } else {
                overrideProjectionVal = params.row.projection
              }
              onChange({
                target: {
                  values: {
                    ...params.row,
                    override: !params.row.override,
                    projection: overrideProjectionVal,
                  },
                  row: params.row,
                },
              })
            }}
          />
        )
      },
    },
    {
      field: 'Projection',
      headerName: 'PROJECTION',
      align: 'center',
      headerAlign: 'center',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      editable: false,
      width: 120,
      renderCell: (params: GridValueGetterParams) => {
        let value = 1
        if (params.row.override) {
          value = params.row.projection
        } else {
          value = Math.ceil(params.row.dailyUse * params.row.days)
        }
        return (
          <TextField
            value={value}
            disabled={!params.row.override}
            onChange={(event) => {
              onChange({
                target: {
                  values: {
                    ...params.row,
                    projection: parseInt(event.target.value),
                  },
                  row: params.row,
                },
              })
            }}
            type="number"
          />
        )
      },
    },
  ]
  return columns
}

const cadenceTypeOptions: SelectOption[] = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Days',
    value: 'CALENDAR_DAYS',
  },
  {
    label: 'Months',
    value: 'CALENDAR_MONTHS',
  },
  {
    label: 'Hours',
    value: 'FLYING_HOURS',
  },
  {
    label: 'Landings',
    value: 'LANDINGS',
  },
  {
    label: 'Cycles',
    value: 'CYCLES',
  },
]

const dueStatusesOptions: AutocompleteOption[] = [
  {
    label: 'Overdue',
    value: 'OVERDUE',
  },
  {
    label: 'In Overflight',
    value: 'IN_TOLERANCE',
  },
  {
    label: 'Approaching Due',
    value: 'APPROACHING_DUE',
  },
  {
    label: 'Good',
    value: 'GOOD',
  },
  {
    label: 'Not Due',
    value: 'NOT_DUE',
  },
]

const ProjectionsAndFilterModal: React.FC<ProjectionsAndFilterModalProps> = ({
  tableVariant,
  open,
  onClose,
  onUpdateFilter,
}) => {
  const {
    loadAircraftList,
    loading: isAircraftListLoading,
    aircrafts,
  } = useAircraftList()

  const {
    duelistFiltersData: { projectionFilterData },
  } = useMaintenanceItemTable(tableVariant)

  const defaultValues = useMemo(() => {
    const aircraftIds = Object.keys(aircrafts)
    const gridRows = projectionFilterData.projectionGrid.map((row, index) => {
      if (aircraftIds.length > 0) {
        if (index === 0 || index === 2) {
          return {
            ...row,
            dailyUse:
              aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyLandings,
          }
        }
        return {
          ...row,
          dailyUse:
            aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyFlyingTime,
        }
      }
      return row
    })

    return {
      ...projectionFilterData,
      projectionGrid: gridRows,
    }
  }, [projectionFilterData, aircrafts, isAircraftListLoading])

  const formMethods = useForm({
    defaultValues,
  })
  const { reset, control, setValue, watch } = formMethods
  const orgName = useOrgName()
  const gridValues = watch('projectionGrid')
  const date = watch('date')

  useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
  }, [])

  useEffect(() => {
    if (date) {
      const today = dayjs()
      const selectedDate = dayjs(date)
      let days = Math.ceil(selectedDate.diff(today, 'day', true))
      if (days <= 0) {
        days = 0
      }
      setValue(
        'projectionGrid',
        gridValues.map((row) => ({
          ...row,
          days,
          projection: !row.override
            ? Math.ceil(row.dailyUse * days)
            : row.projection,
        }))
      )
    }
  }, [date])

  const onGridDataChange = (event: GridRowChangeEvent) => {
    const gridRows = JSON.parse(JSON.stringify(gridValues))
    const index = gridRows.findIndex((r) => r.id === event.target.row.id)
    gridRows[index] = event.target.values
    setValue('projectionGrid', gridRows)
  }

  const resetData = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const gridRows = JSON.parse(JSON.stringify(initialProjectionFilterData))
    const aircraftIds = Object.keys(aircrafts)
    if (aircraftIds.length > 0 && !isAircraftListLoading) {
      // Set the daily use values from the first aircraft
      // TODO: this needs to be updated to handle multiple aircrafts
      gridRows.projectionGrid[0].dailyUse =
        aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyLandings
      gridRows.projectionGrid[1].dailyUse =
        aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyFlyingTime
      gridRows.projectionGrid[2].dailyUse =
        aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyLandings
      gridRows.projectionGrid[3].dailyUse =
        aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyFlyingTime
    }
    reset(gridRows as DuelistFilterProjection)
  }

  const handleSubmit = (data) => {
    onUpdateFilter({
      ...data,
      cadenceType: data.cadenceType === '' ? undefined : data.cadenceType,
      date: data.date ? data.date : undefined,
    })
  }

  const onCloseModal = () => {
    reset(projectionFilterData)
    onClose()
  }

  return (
    <ReusableModal
      open={open}
      onClose={onCloseModal}
      title="Duelist Projections & Filter"
      description="Adjust Calculations used in utilization to find items coming due."
    >
      {isAircraftListLoading ? (
        <LoadingOverlay />
      ) : (
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <div>
            <div className="flex flex-col gap-3 p-3">
              <div>
                <DatePicker
                  className="col-span-2"
                  name={'date'}
                  label="Date"
                  disablePast
                  closeOnSelect
                  slotProps={{ textField: { size: 'small', fullWidth: false } }}
                />
              </div>
              <Controller
                name={'projectionGrid'}
                control={control}
                render={({ field: { value } }) => (
                  <DataGrid
                    rows={value}
                    columns={getProjectionGridColumns(onGridDataChange)}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    hideFooterPagination
                    hideFooter
                  />
                )}
              />

              <div className="grid grid-cols-3 gap-2">
                <div>
                  <SelectDropdown
                    name="cadenceType"
                    label="Interval Type"
                    options={cadenceTypeOptions}
                    fullWidth
                    className="my-1"
                  />
                  <Autocomplete
                    name="statuses"
                    label="Due Status"
                    options={dueStatusesOptions}
                    multiple
                    className="my-1"
                  />
                </div>
                <div className="grid grid-rows-2">
                  <Checkbox name="isOptional" label="Is Optional" />
                  <Checkbox name="isPartBased" label="Is Part Associated" />
                </div>
                <div>
                  <TextField
                    placeholder="Tags"
                    name="notes"
                    multiline
                    rows={3}
                    fullWidth
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between rounded-b-xl bg-[#E4E4E4] px-4 py-3">
              <Button color="warning" size="small" onClick={resetData}>
                Reset
              </Button>
              <div className="flex gap-2">
                {/* //TODO: Add save filter functionality */}
                {/* <Button size="small" variant="outlined" color="base" onClick={onClose}>
                Save Filter
              </Button> */}
                <Button size="small" type="submit">
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </ReusableModal>
  )
}

export default ProjectionsAndFilterModal
