import { OrgPermissions } from '@wingwork/common/src/constants/permissions'

import { permitState } from 'permit-fe-sdk'
import store from 'src/store'

const useHasPermission = (
  permission: OrgPermissions,
  aircraftId?: string | undefined
): boolean => {
  // get orgHasPermissions from redux store, which is set in AbilityLoader
  const orgHasPermissions =
    store.getState().customRequestHeaders.orgHasPermissions
  // if RBAC is not enabled globally or the org does not have permissions feature enabled, allow all
  if (process.env.RBAC_ENABLED !== 'true' || !orgHasPermissions) {
    return true
  }

  // check specific permission
  const resource =
    aircraftId &&
    ['compliance', 'work-order', 'maintenance-item'].includes(
      permission.resource
    )
      ? `${permission.resource}:${aircraftId}`
      : permission.resource
  return permitState?.check(permission.action, resource)
}

export default useHasPermission
