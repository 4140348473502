import { get } from 'lodash'
import { ComponentUsageLog, AircraftComponent } from '../graphql'

export const someHelperFunction = () => {
  console.log('someHelperFunction is called')
}

export const roundToPrecision = (num: number, places = 2): number => {
  if (Number.isInteger(num)) {
    return num
  }
  const multiplier = Math.pow(10, places)
  return Math.round(num * multiplier) / multiplier
}

export const sortComponentLikeObjects =
  <T>(nameString: string) =>
  (items: T[]): T[] => {
    if (!items) return []
    const arr = [...items] // create a copy of the array

    arr.sort((a, b) => {
      const nameA = get(a, nameString).toUpperCase() // ignore upper and lowercase
      const nameB = get(b, nameString).toUpperCase() // ignore upper and lowercase

      if (nameA === 'AIRFRAME') return -1
      if (nameB === 'AIRFRAME') return 1

      const isEngineA = nameA.includes('ENGINE')
      const isEngineB = nameB.includes('ENGINE')

      if (isEngineA && isEngineB) return nameA < nameB ? -1 : 1
      if (isEngineA) return -1
      if (isEngineB) return 1

      return nameA < nameB ? -1 : 1
    })

    return arr
  }

export const sortComponentUsageLogs =
  sortComponentLikeObjects<ComponentUsageLog>('component.name')
export const sortAircraftComponents =
  sortComponentLikeObjects<AircraftComponent>('name')
