import React, { useEffect, useState, useRef } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import FlightIcon from '@mui/icons-material/Flight'
import SearchIcon from '@mui/icons-material/Search'
import {
  Modal,
  Paper,
  Typography,
  InputBase,
  Grid,
  Card,
  CardContent,
  IconButton,
} from '@mui/material'

import useAircraftRecords from 'src/hooks/requests/useAircraftRecords'
import useDiscriminator from 'src/hooks/useDiscriminator'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, setModalData } from 'src/slices/modal'

interface AircraftSelectorModalProps {
  discriminator?: string
  onSuccess?: (aircraftId: string) => void
  onBail?: () => void
}

const AircraftSelectorModal: React.FC<AircraftSelectorModalProps> = ({
  discriminator,
  onSuccess,
  onBail,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch()
  const orgName = useOrgName()
  const searchInputRef = useRef<HTMLInputElement>(null)

  const { aircrafts, loading, hasLoaded, loadAircraftList } =
    useAircraftRecords()

  const open =
    useSelector((state) => state.modal.modals?.aircraftSelectorModal?.isOpen) ??
    false

  const metadata =
    useSelector((state) => state.modal.modals?.aircraftSelectorModal?.data) ??
    {}

  const show = useDiscriminator(open, discriminator, metadata?.discriminator)

  useEffect(() => {
    if (show) {
      loadAircraftList({ orgSlug: orgName })
      dispatch(
        setModalData({
          name: 'aircraftSelectorModal',
          data: { loading: true },
        })
      )
    }
  }, [show, orgName])

  useEffect(() => {
    if (show && hasLoaded) {
      dispatch(
        setModalData({
          name: 'aircraftSelectorModal',
          data: { loading: false },
        })
      )
      // Focus the search input when the modal is fully loaded and visible
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 0)
    }
  }, [show, hasLoaded])

  const handleClose = () => {
    dispatch(closeModal({ name: 'aircraftSelectorModal' }))
    setSearchTerm('')
  }

  const handleChange = (aircraftId: string) => {
    onSuccess(aircraftId)
    handleClose()
  }

  const handleBail = () => {
    onBail?.()
    handleClose()
  }

  const filteredAircrafts = Object.values(aircrafts).filter((aircraft) => {
    const searchString =
      `${aircraft.serialNumber} ${aircraft.tailNumber} ${aircraft.model}`.toLowerCase()
    return searchString.includes(searchTerm.toLowerCase())
  })

  return (
    <Modal
      open={show && hasLoaded && Object.keys(aircrafts).length > 1}
      onClose={handleBail}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal
    >
      <Paper className="absolute left-1/2 top-1/2 w-4/5 max-w-4xl -translate-x-1/2 -translate-y-1/2 transform rounded-xl p-6">
        <Typography variant="h6" component="h3" className="mb-4">
          Select Aircraft
          <IconButton
            aria-label="close"
            onClick={handleBail}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <InputBase
          inputRef={searchInputRef}
          placeholder="Filter aircraft by serial or tail..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={<SearchIcon />}
          fullWidth
          className="mb-4"
        />
        <Grid container spacing={2}>
          {filteredAircrafts.map((aircraft) => (
            <Grid item xs={12} sm={6} md={4} key={aircraft.id}>
              <Card
                onClick={() => handleChange(aircraft.id)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { bgcolor: 'action.hover' },
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    <FlightIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {aircraft.tailNumber}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {aircraft.model} / {aircraft.serialNumber}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Modal>
  )
}

export default AircraftSelectorModal
