import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { get, isEmpty } from 'lodash'
import * as Yup from 'yup'

dayjs.extend(utc)

import {
  MaintenanceItem,
  ComponentUsageLog,
  AircraftUsageLog,
  MaintenanceCadenceType,
  MaintenanceNextDue,
} from '../graphql'
import {
  getNextDueValueAsStringArray,
  nextDueValueSchema,
  getCadenceAsStringArray,
  cadenceValueSchema,
  calculateNextDueValue,
  getRemainingValueFromNextDueValue,
  getRemainingValueAsStringArray,
  toleranceSchemaV1,
  NextDueValue,
  CadenceValue,
  UNIT_MAP,
} from '../jsonObjects'

export const formatDateForDisplayInUtc = (
  date: string | Date | dayjs.Dayjs | undefined,
  format = 'MMM DD, YYYY'
) => {
  return formatDateForDisplay(date, true, format)
}

export const formatDateForDisplayInLocal = (
  date: string | Date | dayjs.Dayjs | undefined,
  format = 'MMM DD, YYYY'
) => {
  return formatDateForDisplay(date, false, format)
}

export const formatDateForDisplay = (
  date: string | Date | dayjs.Dayjs | undefined,
  showInUtc: boolean,
  format = 'MMM DD, YYYY'
): string => {
  if (!date) {
    return ''
  }

  const inputDate = showInUtc ? dayjs.utc(date) : dayjs(date)
  if (inputDate.isBefore('1970-01-01')) {
    return '---'
  }
  // if it's not a valid date
  if (!inputDate.isValid()) {
    return '---'
  }
  return inputDate.format(format)
}

export const CADENCE_TYPE_TO_LABEL = {
  FLYING_HOURS: 'H',
  CYCLES: 'C',
  LANDINGS: 'L',
  CALENDAR_MONTHS: 'M',
  CALENDAR_DAYS: 'D',
}

export const CADENCE_VALUE_TO_LABEL = {
  flying_hours: 'Flying Hours',
  cycles: 'Cycles',
  landings: 'Landings',
  months: 'Months',
  days: 'Days',
}

export const CADENCE_VALUE_TO_CADENCE_TYPE = {
  flying_hours: 'FLYING_HOURS',
  cycles: 'CYCLES',
  landings: 'LANDINGS',
  months: 'CALENDAR_MONTHS',
  days: 'CALENDAR_DAYS',
}

export const getCadenceValueFieldSchema = (errorId: string) => {
  const cadenceValueFieldSchema = Yup.string()
    .optional()
    .test(errorId, 'Enter Valid Number', (value) => {
      if (value === undefined || value === null) return true
      if (!value) return false
      return !isNaN(parseFloat(value)) && parseFloat(value) > 0
    })
  return cadenceValueFieldSchema
}

export const optionalValidNumberSchema = Yup.string()
  .nullable()
  .test('is-not-empty-and-number', 'Enter Valid Number', (value) => {
    if (!value) return true
    return !isNaN(parseFloat(value)) && parseFloat(value) >= 0
  })

export const getUpdatedCadenceType = (cadenceValue): MaintenanceCadenceType => {
  const valueCpy = JSON.parse(JSON.stringify(cadenceValue))
  delete valueCpy['tolerance']
  const cadenceValueKeys = Object.keys(valueCpy)
  if (cadenceValueKeys.includes('flying_hours')) {
    return 'FLYING_HOURS'
  } else if (cadenceValueKeys.includes('cycles')) {
    return 'CYCLES'
  } else if (cadenceValueKeys.includes('landings')) {
    return 'LANDINGS'
  } else if (cadenceValueKeys.includes('months')) {
    return 'CALENDAR_MONTHS'
  } else {
    return 'CALENDAR_DAYS'
  }
}

export const getNextDueCadenceType = (nextDueVal): MaintenanceCadenceType => {
  // Create a new object excluding properties with '' or 0 values
  const cleanedNextDueVal = Object.fromEntries(
    Object.entries(nextDueVal).filter(
      ([_, value]) => value !== '' && value !== 0
    )
  )

  const nextDueValueKeys = Object.keys(cleanedNextDueVal)
  if (nextDueValueKeys.includes('flying_hours')) {
    return 'FLYING_HOURS'
  } else if (nextDueValueKeys.includes('cycles')) {
    return 'CYCLES'
  } else if (nextDueValueKeys.includes('landings')) {
    return 'LANDINGS'
  } else if (nextDueValueKeys.includes('date')) {
    return 'CALENDAR_MONTHS'
  } else {
    return 'CALENDAR_DAYS'
  }
}

export const getToleranceValue = (mtxItem: MaintenanceItem): string => {
  const mtxCadenceType = mtxItem.cadenceType
  const cadenceValue = cadenceValueSchema.cast(
    get(mtxItem, ['cadenceValue'], {})
  )

  //It makes sense to return 0 if tolerance is not set??
  switch (mtxCadenceType) {
    case 'FLYING_HOURS':
      return cadenceValue?.tolerance?.flying_hours?.toString() || '0'
    case 'CYCLES':
      return cadenceValue?.tolerance?.cycles?.toString() || '0'
    case 'LANDINGS':
      return cadenceValue?.tolerance?.landings?.toString() || '0'
    case 'CALENDAR_MONTHS':
      return cadenceValue?.tolerance?.months?.toString() || '0'
  }
}

export const getLastComplianceHours = (
  maintenanceItem: MaintenanceItem,
  componentUsageLog: ComponentUsageLog
) => {
  const importedDataCompliance = get(maintenanceItem, [
    'importedDataCompliance',
  ])
  return parseFloat(
    get(componentUsageLog, ['totalTimeSinceNew']) ||
      get(importedDataCompliance, ['logHours'])
  )
}

export const getLastComplianceCycles = (
  maintenanceItem: MaintenanceItem,
  componentUsageLog: ComponentUsageLog
) => {
  const importedDataCompliance = get(maintenanceItem, [
    'importedDataCompliance',
  ])
  return parseFloat(
    get(componentUsageLog, ['cycleSinceNew']) ||
      get(importedDataCompliance, ['logCycles'])
  )
}

export const getLastComplianceLandings = (
  maintenanceItem: MaintenanceItem,
  componentUsageLog: ComponentUsageLog
) => {
  const importedDataCompliance = get(maintenanceItem, [
    'importedDataCompliance',
  ])
  return parseFloat(
    get(componentUsageLog, ['cycleSinceNew']) ||
      get(importedDataCompliance, ['logLandings'])
  )
}

export const getLastComplianceDate = (
  maintenanceItem: MaintenanceItem
): Date | null => {
  const dateValue =
    get(maintenanceItem, ['lastComplianceDate']) ??
    get(maintenanceItem, ['importedDataCompliance', 'logDate'])
  if (dateValue) {
    return dayjs.utc(dateValue).toDate()
  } else {
    return null
  }
}

export const getAbsoluteLastComplianceValues = (
  maintenanceItem: MaintenanceItem,
  componentUsageLog: ComponentUsageLog
) => {
  const lastComplianceHours = getLastComplianceHours(
    maintenanceItem,
    componentUsageLog
  )
  const lastComplianceDate = getLastComplianceDate(maintenanceItem)
  const lastComplianceCycles = getLastComplianceCycles(
    maintenanceItem,
    componentUsageLog
  )
  const lastComplianceLandings = getLastComplianceLandings(
    maintenanceItem,
    componentUsageLog
  )
  return {
    flying_hours: lastComplianceHours,
    date: lastComplianceDate,
    cycles: lastComplianceCycles,
    landings: lastComplianceLandings,
  }
}

export const getLastComplianceTimeString = (
  maintenanceItem: MaintenanceItem
): string[] => {
  // Check for lastComplianceStamp
  const lastComplianceStamp = get(maintenanceItem, ['lastComplianceStamp'])

  const mtxItemTrackedByComponentId = maintenanceItem.trackedByComponent?.id

  const componentUsageLog = lastComplianceStamp?.ComponentUsageLog.find(
    (log) => log.component.id === mtxItemTrackedByComponentId
  )

  const value = []
  const lastComplianceDate = getLastComplianceDate(maintenanceItem)
  if (lastComplianceDate) {
    value.push(formatDateForDisplayInUtc(lastComplianceDate))
  }
  const lastComplianceHours = getLastComplianceHours(
    maintenanceItem,
    componentUsageLog
  )
  if (isFinite(lastComplianceHours)) {
    value.push(`${lastComplianceHours.toFixed(1)} Hours`)
  }
  if (maintenanceItem.trackedByComponent?.name?.toUpperCase() === 'AIRFRAME') {
    const lastComplianceLandings = getLastComplianceLandings(
      maintenanceItem,
      componentUsageLog
    )
    if (isFinite(lastComplianceLandings)) {
      value.push(`${lastComplianceLandings} Landings`)
    }
  } else {
    const lastComplianceCycles = getLastComplianceCycles(
      maintenanceItem,
      componentUsageLog
    )
    if (isFinite(lastComplianceCycles)) {
      value.push(`${lastComplianceCycles} Cycles`)
    }
  }
  return value
}

export const getIntervalCadenceValues = (
  maintenanceItem: MaintenanceItem
): string[] => {
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  return Object.keys(cadenceValue).filter((key) => key !== 'tolerance')
}

export const getIntervals = (maintenanceItem: MaintenanceItem): string[] => {
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  return getCadenceAsStringArray(cadenceValue)
}

export const getTolerances = (maintenanceItem: MaintenanceItem): string[] => {
  const cadenceValue: any = maintenanceItem.cadenceValue
  const toleranceValues = toleranceSchemaV1.cast(cadenceValue?.tolerance)
  return getCadenceAsStringArray(toleranceValues)
}

export const getIntervalString = (maintenanceItem: MaintenanceItem): string => {
  const intervals = getIntervals(maintenanceItem)
  return intervals.length > 0 ? intervals.join(' or ') : ''
}

export const getNextDueString = (
  maintenanceItem: MaintenanceItem,
  discardOverride = false
): string[] => {
  if (!maintenanceItem?.maintenanceNextDue?.length) return []

  const isOverrideProvided = !isEmpty(
    maintenanceItem.maintenanceNextDue[0]?.nextDueOverride || {}
  )
  const nextDueValue = nextDueValueSchema.cast(
    !discardOverride && isOverrideProvided
      ? maintenanceItem.maintenanceNextDue[0]?.nextDueOverride
      : maintenanceItem.maintenanceNextDue[0]?.nextDueValue
  )
  return getNextDueValueAsStringArray(nextDueValue)
}

export const getMaxNextDueString = (
  maintenanceItem: MaintenanceItem
): string[] => {
  if (maintenanceItem.maintenanceNextDue.length === 0) return []
  const isOverrideProvided = !isEmpty(
    maintenanceItem.maintenanceNextDue[0]?.nextDueOverride || {}
  )
  // If override is provided, return the override value without applying any tolerance
  if (isOverrideProvided) {
    return getNextDueValueAsStringArray(
      nextDueValueSchema.cast(
        maintenanceItem.maintenanceNextDue[0]?.nextDueOverride
      )
    )
  }

  const nextDueValue = nextDueValueSchema.cast(
    maintenanceItem.maintenanceNextDue[0]?.nextDueValue
  )

  const result: string[] = []
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  const toleranceValues = cadenceValueSchema.cast(
    maintenanceItem.cadenceValue
  )?.tolerance
  fields.forEach((field) => {
    if (nextDueValue[field]) {
      if (field === 'date') {
        if (toleranceValues?.['months']) {
          result.push(
            formatDateForDisplayInUtc(
              dayjs
                .utc(nextDueValue[field])
                .add(toleranceValues['months'], 'months')
            )
          )
        } else if (toleranceValues?.['days']) {
          result.push(
            formatDateForDisplayInUtc(
              dayjs
                .utc(nextDueValue[field])
                .add(toleranceValues['days'], 'days')
            )
          )
        } else {
          result.push(formatDateForDisplayInUtc(nextDueValue[field]))
        }
      } else {
        if (toleranceValues?.[field]) {
          result.push(
            `${nextDueValue[field] + toleranceValues[field]} ${UNIT_MAP[field]}`
          )
        } else {
          result.push(`${nextDueValue[field]} ${UNIT_MAP[field]}`)
        }
      }
    }
  })
  return result
}

export const getNextDueAdjustmentString = (
  maintenanceNextDue: MaintenanceNextDue
): string[] => {
  if (!maintenanceNextDue) return []
  const isOverrideProvided = !isEmpty(maintenanceNextDue?.nextDueOverride || {})
  if (!isOverrideProvided) return []
  const nextDueOverride = nextDueValueSchema.cast(
    maintenanceNextDue?.nextDueOverride
  )
  const nextDueValue = nextDueValueSchema.cast(maintenanceNextDue?.nextDueValue)

  const result = []
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  fields.forEach((field) => {
    if (nextDueValue[field] !== nextDueOverride[field]) {
      if (field === 'date') {
        const diff = dayjs
          .utc(nextDueOverride[field])
          .diff(dayjs.utc(nextDueValue[field]), 'day', true)
        const diffRoundedUp = Math.sign(diff) * Math.ceil(Math.abs(diff))
        const diffWithSign =
          diffRoundedUp > 0 ? `+${diffRoundedUp}` : diffRoundedUp
        result.push(`${diffWithSign} D`)
      } else {
        const diff = nextDueOverride[field] - nextDueValue[field]
        const diffWithSign = diff > 0 ? `+${diff}` : diff
        result.push(`${diffWithSign} ${UNIT_MAP[field]}`)
      }
    }
  })
  return result
}

export const getRemainingValueString = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog
): string[] => {
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  if (maintenanceItem.nextDueStatus === 'NOT_DUE') return []
  const remainingValue = getRemainingValueFromNextDueValue(
    maintenanceItem.maintenanceNextDue[0],
    cadenceValue,
    componentUsageLog
  )
  return getRemainingValueAsStringArray(remainingValue)
}

export const getCalculatedNextDueString = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog
): string[] => {
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  const nextDueValue = calculateNextDueValue(
    maintenanceItem.cadenceType,
    cadenceValue,
    componentUsageLog,
    new Date(maintenanceItem.lastComplianceDate),
    false
  )
  return getNextDueValueAsStringArray(nextDueValue)
}

export const getCalculatedNextDueValuesFromLastCompliance = (
  maintenanceItem: MaintenanceItem,
  overrideCadenceValue?: CadenceValue
): NextDueValue => {
  const complianceUsageLog = maintenanceItem.lastComplianceStamp
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = complianceUsageLog?.ComponentUsageLog.find(
    (log) => log.componentId === trackedByComponentId
  )
  const lastComplianceUsageLog = {
    usageAsOf: getLastComplianceDate(maintenanceItem),
    totalTimeSinceNew: getLastComplianceHours(
      maintenanceItem,
      componentUsageLog
    ),
    cycleSinceNew:
      maintenanceItem.trackedByComponent.name.toUpperCase() === 'AIRFRAME'
        ? getLastComplianceLandings(maintenanceItem, componentUsageLog)
        : getLastComplianceCycles(maintenanceItem, componentUsageLog),
  }
  const nextDueValue = calculateNextDueValue(
    maintenanceItem.cadenceType,
    overrideCadenceValue || cadenceValue,
    lastComplianceUsageLog,
    getLastComplianceDate(maintenanceItem),
    false
  )
  return nextDueValue
}

export const getCalculatedNextDueObject = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog,
  overrideCadenceValue?: CadenceValue
): NextDueValue => {
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  const nextDueValue = calculateNextDueValue(
    maintenanceItem.cadenceType,
    overrideCadenceValue || cadenceValue,
    componentUsageLog,
    getLastComplianceDate(maintenanceItem),
    false
  )
  return nextDueValue
}
