import * as React from 'react'

import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import { Button, Checkbox, Chip, FormControlLabel } from '@mui/material'
import { isEmpty } from 'lodash'
import { useDebounce } from 'usehooks-ts'

import { useAuth } from 'src/auth'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'
import { useOrgName } from 'src/hooks/useOrgName'
import { isNotEmpty } from 'src/utils/helpers'
import useAircraftRecords from 'src/hooks/requests/useAircraftRecords'

import AircraftFilter from '../AircraftFilter/AircraftFilter'
import ProjectionsAndFilterModal from '../DueListTable/ProjectionsAndFilterModal'
import ManageTableColumnsModal from '../ManageTableColumnsModal'
import Searchbar from '../Searchbar/Searchbar'
import TimeFilter from '../TimeFilter/TimeFilter'

interface DueListTableFiltersProps {
  tableVariant: TableVariant
  searchEnabled?: boolean
  aircraftFilterEnabled?: boolean
  projectinFilterEnabled?: boolean
  usageFilterEnabled?: boolean
  manageColumnsEnabled?: boolean
}

const duelistPowerSearchOptions = {
  ataCode: 'ATA Code',
  title: 'Title',
  manufactureCode: 'Manufacture Code',
  ataManufactureCode: 'ATA Manufacture Code',
  disposition: 'Disposition',
  partNumber: 'Part Number',
  serialNumber: 'Serial Number',
  maintenanceItemTags: 'Maintenance Item Tags',
  trackedByComponentName: 'Tracking Component Name',
}

const manageColumnOptions = {
  ataCode: 'ATA/Code',
  typeDescription: 'Type/Description',
  lastComplianceDate: 'Compliance',
  interval: 'Interval',
  nextDueStatus: 'Next Due',
  maxDue: 'Max Due',
  calculatedNextDueAt: 'Remaining',
  partSerial: 'Part/Serial Number',
}

const DueListTableFilters: React.FC<DueListTableFiltersProps> = ({
  tableVariant,
  searchEnabled = true,
  aircraftFilterEnabled = true,
  projectinFilterEnabled = true,
  usageFilterEnabled = true,
  manageColumnsEnabled = false,
}) => {
  const [showProjectionsAndFilterModal, setShowProjectionsAndFilterModal] =
    React.useState(false)
  const [showManageColumnsModal, setShowManageColumnsModal] =
    React.useState(false)
  const {
    duelistFiltersData,
    setPowerSearchTerms,
    setFuzzySearchTerms,
    setProjectionFilters,
    resetetAircraftFilter,
    setAircraftFilter,
    getSearchConfig,
    setEnabledColumns,
    setShowChildItems,
    showChildItems,
  } = useMaintenanceItemTable(tableVariant)
  const {
    aircrafts: aircraftsFilterData,
    powerSearchTerms,
    fuzzySearchTerms,
  } = duelistFiltersData
  const orgName = useOrgName()

  const [hasUserChangedLocalSearchValue, setHasUserChangedLocalSearchValue] =
    React.useState(false)
  const [localSearchValue, setLocalSearchValue] = React.useState<
    string | object
  >({})

  const debouncedSearchValue = useDebounce(localSearchValue, 500)

  const onSearchAircraftChange = (searchVal: string | object) => {
    setLocalSearchValue(searchVal)
  }
  React.useEffect(() => {
    if (!localSearchValue) {
      if (isEmpty(powerSearchTerms) && isNotEmpty(fuzzySearchTerms)) {
        setLocalSearchValue(fuzzySearchTerms)
      }
      if (isEmpty(fuzzySearchTerms) && isNotEmpty(powerSearchTerms)) {
        setLocalSearchValue(powerSearchTerms)
      }
      setHasUserChangedLocalSearchValue(false)
    }
  }, [powerSearchTerms, fuzzySearchTerms])

  React.useEffect(() => {
    if (hasUserChangedLocalSearchValue) {
      if (typeof debouncedSearchValue === 'string') {
        setFuzzySearchTerms(debouncedSearchValue)
      } else {
        setPowerSearchTerms(debouncedSearchValue)
      }
    }
  }, [debouncedSearchValue])

  const { aircrafts, loadAircraftList } = useAircraftRecords()
  React.useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
  }, [])

  const { currentUser } = useAuth()

  const showChildItemsCheckbox = React.useMemo(
    () =>
      [
        'reaves.worrell@imail.org',
        'karthik.srinivasan@getwingwork.com',
        'matt.castellini@getwingwork.com',
        'matt.mcdermott@getwingwork.com',
        'meddy@stein.aero',
        'jason@stein.aero',
      ].includes(currentUser?.email),
    [currentUser]
  )

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-wrap items-center gap-1">
        <div className="min-w-[400px] flex-grow">
          {searchEnabled && (
            <Searchbar
              placeholder="Start typing to search..."
              onChange={onSearchAircraftChange}
              iconPosition="right"
              value={localSearchValue}
              powerSearch
              powerSearchOptions={duelistPowerSearchOptions}
              setHasUserChangedLocalSearchValue={
                setHasUserChangedLocalSearchValue
              }
            />
          )}
        </div>
        <div className="flex w-auto flex-wrap items-center gap-1">
          {manageColumnsEnabled && (
            <React.Fragment>
              <Button
                variant="outlined"
                color="base"
                startIcon={<ViewWeekIcon />}
                className="relative"
                onClick={() => {
                  setShowManageColumnsModal(true)
                }}
                sx={{ textTransform: 'none' }}
              >
                Manage Columns
              </Button>
              <ManageTableColumnsModal
                tableName="MaintenanceItems" // TODO: this should change based on variant - duplicate all existing records with a talbe name DueList
                open={showManageColumnsModal}
                columnOptions={manageColumnOptions}
                onClose={() => {
                  setShowManageColumnsModal(false)
                }}
                onSaveDone={(columns) => {
                  setEnabledColumns(columns)
                }}
              />
            </React.Fragment>
          )}

          {aircraftFilterEnabled && (
            <AircraftFilter
              aircrafts={Object.values(aircrafts)}
              setAircraftFilter={setAircraftFilter}
              resetetAircraftFilter={resetetAircraftFilter}
              aircraftsFilterData={aircraftsFilterData}
            />
          )}

          {projectinFilterEnabled && (
            <React.Fragment>
              <Button
                className="w-max"
                variant="outlined"
                color="base"
                onClick={() => {
                  setShowProjectionsAndFilterModal(true)
                }}
              >
                Projection & Filters
              </Button>
              <ProjectionsAndFilterModal
                tableVariant={tableVariant}
                open={showProjectionsAndFilterModal}
                onUpdateFilter={(projectionData) => {
                  setShowProjectionsAndFilterModal(false)
                  setProjectionFilters(projectionData)
                }}
                onClose={() => {
                  setShowProjectionsAndFilterModal(false)
                }}
              />
            </React.Fragment>
          )}
          {usageFilterEnabled && <TimeFilter tableVariant={tableVariant} />}
        </div>
      </div>
      {searchEnabled && showChildItemsCheckbox && (
        <FormControlLabel
          control={<Checkbox />}
          label="Show Child Items"
          onChange={(e) => setShowChildItems(e.target.checked)}
        />
      )}
      {powerSearchTerms && !isEmpty(powerSearchTerms) && (
        <div className="flex items-center gap-1">
          {Object.keys(powerSearchTerms)
            .filter((col) => powerSearchTerms[col])
            .map((colKey, idx) => {
              return (
                <Chip
                  key={idx}
                  size="small"
                  label={`Search '${powerSearchTerms[colKey]}' in ${duelistPowerSearchOptions[colKey]}`}
                  onDelete={() => {
                    const newSearchTerms = { ...powerSearchTerms }
                    delete newSearchTerms[colKey]
                    setPowerSearchTerms(newSearchTerms)
                  }}
                />
              )
            })}
        </div>
      )}
      {fuzzySearchTerms && (
        <div className="flex items-center gap-1">
          <Chip
            size="small"
            label={`Fuzzy Search '${fuzzySearchTerms}'`}
            onDelete={() => {
              setFuzzySearchTerms('')
            }}
          />
        </div>
      )}
    </div>
  )
}

export default DueListTableFilters
