import * as React from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import LockPersonIcon from '@mui/icons-material/LockPerson'

export interface MenuOption {
  label: string
  onClick: () => void
  selected?: boolean
  disabled?: boolean
  disabledTooltip?: string
  locked?: boolean
  lockedTooltip?: string
}

interface DotsMenuProps {
  options: MenuOption[]
  icon?: React.ReactNode
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
}

const ITEM_HEIGHT = 48

const DotsMenu: React.FC<DotsMenuProps> = ({
  options,
  icon = <MoreVertIcon />,
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (option: MenuOption) => {
    setAnchorEl(null)

    if (option.onClick) {
      option.onClick()
    }
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        className="mt-1"
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * (options.length + 0.5), // define the max height of the menu enough to fit all options
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <Tooltip
            key={option.label}
            title={
              (option.locked && option.lockedTooltip) ||
              (option.disabled && option.disabledTooltip) ||
              ''
            }
            arrow
          >
            <span>
              <MenuItem
                selected={option.selected}
                onClick={() => handleClose(option)}
                disabled={option.disabled || option.locked}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {option.label}
                {option.locked && <LockPersonIcon fontSize="small" />}
              </MenuItem>
            </span>
          </Tooltip>
        ))}
      </Menu>
    </div>
  )
}

export default DotsMenu
