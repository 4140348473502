import { useEffect, useState, useRef, useCallback } from 'react'
import { useApolloClient, gql } from '@apollo/client'

const GET_REFERENCES = gql`
  query GetReferences($referenceFilters: ReferenceFilters!) {
    references(referenceFilters: $referenceFilters) {
      id
      name
      extractedText
      pageNumbers
      entityId
      referenceSource {
        id
        name
        fileEntry {
          url
        }
      }
    }
  }
`

interface BatchRequest {
  ids: string[]
  resolve: (value: any) => void
  reject: (error: Error) => void
}

// Singleton batch manager to handle requests across all hook instances
export class ReferencesBatchManager {
  private static instance: ReferencesBatchManager
  private batchQueue: BatchRequest[] = []
  private batchTimeout: NodeJS.Timeout | null = null
  private inFlightRequests = new Set<string>()
  private cache: Record<string, any> = {}
  private client: any = null

  private readonly BATCH_TIMEOUT = 50 // ms to wait before processing batch
  private readonly MAX_BATCH_SIZE = 100 // maximum IDs to process in one request

  static getInstance(): ReferencesBatchManager {
    if (!ReferencesBatchManager.instance) {
      ReferencesBatchManager.instance = new ReferencesBatchManager()
    }
    return ReferencesBatchManager.instance
  }

  setClient(client: any) {
    this.client = client
  }

  async request(ids: string[]): Promise<any> {
    // Filter out IDs that are already in flight or cached
    const newIds = ids.filter(
      (id) => !this.inFlightRequests.has(id) && !this.cache[id]
    )

    if (newIds.length === 0) {
      // Return cached results if all IDs are already processed
      return this.getFromCache(ids)
    }

    return new Promise((resolve, reject) => {
      this.batchQueue.push({ ids: newIds, resolve, reject })
      newIds.forEach((id) => this.inFlightRequests.add(id))
      this.scheduleBatch()
    })
  }

  private scheduleBatch() {
    if (this.batchTimeout) {
      return
    }

    this.batchTimeout = setTimeout(() => {
      this.processBatch()
    }, this.BATCH_TIMEOUT)
  }

  private async processBatch() {
    this.batchTimeout = null
    if (!this.batchQueue.length) return

    // Collect all unique IDs from the queue
    const allIds = new Set<string>()
    this.batchQueue.forEach((request) => {
      request.ids.forEach((id) => allIds.add(id))
    })

    // Process in chunks if needed
    const idsArray = Array.from(allIds)
    const chunks = this.chunkArray(idsArray, this.MAX_BATCH_SIZE)

    try {
      for (const chunk of chunks) {
        const { data } = await this.client.query({
          query: GET_REFERENCES,
          variables: {
            referenceFilters: {
              maintenanceItemIds: chunk,
            },
          },
        })

        // Group references by entityId (maintenanceItemId)
        const referencesByEntityId = data.references.reduce(
          (acc: Record<string, any[]>, ref: any) => {
            if (!acc[ref.entityId]) {
              acc[ref.entityId] = []
            }
            acc[ref.entityId].push(ref)
            return acc
          },
          {}
        )

        // Update cache with new results
        Object.entries(referencesByEntityId).forEach(([entityId, refs]) => {
          this.cache[entityId] = refs
        })
      }

      // Resolve all pending requests
      const currentBatch = this.batchQueue
      this.batchQueue = []

      currentBatch.forEach((request) => {
        request.resolve(this.getFromCache(request.ids))
      })
    } catch (error) {
      // Reject all pending requests
      const currentBatch = this.batchQueue
      this.batchQueue = []

      currentBatch.forEach((request) => {
        request.reject(error as Error)
      })
    } finally {
      // Clear in-flight status
      idsArray.forEach((id) => this.inFlightRequests.delete(id))
    }
  }

  private getFromCache(ids: string[]): Record<string, any> {
    return ids.reduce((acc, id) => {
      acc[id] = this.cache[id] || []
      return acc
    }, {} as Record<string, any>)
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    const chunks: T[][] = []
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size))
    }
    return chunks
  }

  clearCache(maintenanceItemIds?: string[]) {
    if (!maintenanceItemIds) {
      // Clear entire cache if no specific IDs provided
      this.cache = {}
      this.inFlightRequests.clear()
      if (this.batchTimeout) {
        clearTimeout(this.batchTimeout)
        this.batchTimeout = null
      }
      this.batchQueue = []
      return
    }

    // Clear only specific IDs from cache
    maintenanceItemIds.forEach((id) => {
      delete this.cache[id]
      this.inFlightRequests.delete(id)
    })

    // Filter out cleared IDs from pending requests
    this.batchQueue = this.batchQueue
      .map((request) => ({
        ...request,
        ids: request.ids.filter((id) => !maintenanceItemIds.includes(id)),
      }))
      .filter((request) => request.ids.length > 0)

    // If no more pending requests, clear timeout
    if (this.batchQueue.length === 0 && this.batchTimeout) {
      clearTimeout(this.batchTimeout)
      this.batchTimeout = null
    }
  }
}

export function useReferenceBatch(maintenanceItemIds: string[]) {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Record<string, any>>({})
  const [error, setError] = useState<Error | null>(null)
  const batchManager = useRef(ReferencesBatchManager.getInstance())

  useEffect(() => {
    batchManager.current.setClient(client)
  }, [client])

  const refetch = useCallback(
    async (ids?: string[], forceFresh: boolean = false) => {
      setLoading(true)
      try {
        const targetIds = ids || maintenanceItemIds
        if (forceFresh) {
          // Clear cache for the specified IDs before fetching
          batchManager.current.clearCache(targetIds)
        }
        const result = await batchManager.current.request(targetIds)
        setData(result)
      } catch (err) {
        setError(err as Error)
      } finally {
        setLoading(false)
      }
    },
    [maintenanceItemIds]
  )

  useEffect(() => {
    let mounted = true

    const fetchReferences = async () => {
      if (!maintenanceItemIds.length) {
        setLoading(false)
        return
      }

      try {
        const result = await batchManager.current.request(maintenanceItemIds)
        if (mounted) {
          setData(result)
          setLoading(false)
        }
      } catch (err) {
        if (mounted) {
          setError(err as Error)
          setLoading(false)
        }
      }
    }

    fetchReferences()

    return () => {
      mounted = false
    }
  }, [maintenanceItemIds.join(',')])

  const clearCache = useCallback((ids?: string[]) => {
    batchManager.current.clearCache(ids)
  }, [])

  return { loading, error, data, clearCache, refetch }
}
